import {OptionsType, OptionTypeBase} from 'react-select'

interface IndustryOptions extends OptionTypeBase{
  projects: OptionsType<OptionTypeBase>
}

export const mapToOptions = (el: string): OptionTypeBase => ({value: el, label: el})

export const KIND: OptionsType<OptionTypeBase> = [
  {value: "API", label: "API"},
  {value: "DASHBOARD", label: "DASHBOARD"}
]


export const INDUSTRY: OptionsType<IndustryOptions> = [
  {
    value: "Mobilité et transport autonome", label: "Mobilité et transport autonome", projects: [
      {value: "MSM", label: "MSM"},
      {value: "CTI", label: "CTI"},
      {value: "IVA", label: "IVA"},
      {value: "SVA", label: "SVA"},
      {value: "3SA", label: "3SA"},
      {value: "SAM", label: "SAM"},
      {value: "SVR", label: "SVR"},
      {value: "ADS", label: "ADS"},
      {value: "VVA", label: "VVA"},
      {value: "EPI", label: "EPI"}
    ]
  },
  {
    value: "Industrie du futur", label: "Industrie du futur", projects: [
      {value: "HSA", label: "HSA"},
      {value: "MPO", label: "MPO"}
    ]
  },
  {
    value: "Défense et sécurité", label: "Défense et sécurité", projects: [
      {value: "BST", label: "BST"},
      {value: "EIC", label: "EIC"},
      {value: "SCA", label: "SCA"},
      {value: "Confiance_AI", label: "Confiance.ai"}
    ]
  },
  {
    value: "Environnement et développement durable", label: "Environnement et développement durable", projects: [
      {value: "LCE", label: "LCE"},
      {value: "PSE", label: "PSE"}
    ]
  }
]

export const LINK_TYPES: OptionsType<OptionTypeBase> = [
  {value: "git", label: "Git"},
  {value: "docker", label: "Docker"},
  {value: "wiki", label: "Wiki"},
  {value: "backend", label: "Backend"},
  {value: "swagger", label: "Swagger"},
  {value: "dashboard", label: "Dashboard"}
]
