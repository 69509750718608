import React from 'react'
import {Link, useParams} from "react-router-dom";
import {useServiceByHandle} from 'common/Service'
import SimpleCard from 'components/card/SimpleCard'
import ProjectInfo from 'layout/main/view/ProjectInfo'
import TechInfo from 'layout/main/view/TechInfo'
import SwaggerReact from 'components/swagger/SwaggerReact'
import {deleteService} from 'domain/services'
import IsOnline from 'components/badge/IsOnline'
import {useRights} from 'config/auth'


const ServiceView: React.FC = () => {
  const {handle, project} = useParams()
  const service = useServiceByHandle(handle)

  const canModify = useRights()[1]
  const canDelete = useRights()[1] // May change

  if (!service) {
    return <h1 className="text-center">{`Aucun service de projet ${project} n'as pour id ${handle}`}</h1>
  }

  return (
    <div className="md:grid sm:grid-cols-1 md:grid-cols-2 gap-3 lg:mx-8 md:mx-6 sm:mx-2">
      <div className="col-span-2 m-6 flex">
        {canModify && <Link to="/" onClick={() => deleteService(service.handle)}>
          <button className="bg-red-600 p-2 text-white rounded-lg">
            Supprimer
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5 inline ml-1 mb-1 hover:text-gray-800">
              <path fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"/>
            </svg>
          </button>
        </Link>}
        <h1 className=" text-center text-4xl flex-grow">
          {service.name}
        </h1>
        {canDelete && <Link to={`/form/${service.handle}`}>
          <button className="bg-blue-800 p-2 text-white rounded-lg">Editer
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5 inline ml-1 mb-1">
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"/>
              <path fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                    clipRule="evenodd"/>
            </svg>
          </button>
        </Link>}
      </div>

      {/** Description **/}
      <SimpleCard title="Description" className="col-span-2 leading-relaxed">
        <p className="whitespace-pre-wrap">{service?.description}</p>
      </SimpleCard>

      {/** Dependencies **/}
      <ProjectInfo service={service}/>
      <TechInfo service={service}/>

      {/** Main Card **/}
      {
        service?.swagger &&
        <SimpleCard title={<>Swagger <IsOnline className="w-8 inline" service={service}/></>}
                    className="col-span-2"
                    topRightButton={service.techInfo.resourceLinks.find(r => r.type === "swagger") &&
                    <button>GO TO</button>}>
          <SwaggerReact swaggerContent={service.swagger}/>
        </SimpleCard>
      }
      {
        service?.dashboard &&
        <SimpleCard title="Dashboard du Service" className="col-span-2 h-screen">
          <iframe className="w-full h-full" src={service?.dashboard} title={`dashboard-${service.handle}`}/>
        </SimpleCard>
      }
    </div>
  )
}

export default ServiceView