import Keycloak from 'keycloak-js'
import {useKeycloak} from '@react-keycloak/web'

export const keycloak = Keycloak({
    clientId: "services-portal",
    url: "https://keycloak.irtsysx.fr/auth/",
    realm: "services-portal"
})

export const initConfig: Keycloak.KeycloakInitOptions = {
  flow: 'hybrid',
  onLoad: 'login-required',
}


export interface ParsedToken{
  nonce?: string
  email?: string
  groups?: Array<string>
}


export const useRights = () => {
  const [keycloak, init] = useKeycloak()

  const isAuth = keycloak?.authenticated

  const email: string | undefined = (keycloak?.idTokenParsed as ParsedToken)?.email

  const isIRT = email?.includes('irt-systemx.fr')
  const isExt = email?.includes('ext.irt-systemx.fr') // Maybe delete that for extern

  return [isAuth, isIRT, isExt, init]
}

