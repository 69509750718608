import {useFilteredServices} from 'common/Filter'
import {useEffect, useState} from 'react'
import {isRequestOK} from 'domain/services'

export interface ResourceLink {
  url: string
  type: string
}

export interface IService {
  id?: string
  kind: "API" | "DASHBOARD" | string
  handle: string
  version?: string
  name: string
  swagger?: string
  dashboard?: string
  description: string
  icon?: string
  irtInfo: {
    project: string
    industry: string
    agavRef?: string
    theme: string
    contributors: Array<string>
  }
  techInfo: {
    technologies: Array<string>
    dependencies: Array<string>
    resourceLinks: Array<ResourceLink>
  }
  tags: Array<string>
}

export const createNewService = (): IService => ({
  kind: "",
  handle: "",
  name: "",
  description: "",
  irtInfo: {
    project: "",
    industry: "",
    theme: "",
    contributors: [],
  },
  techInfo: {
    technologies: [],
    dependencies: [],
    resourceLinks: [],
  },
  tags: []
})

export const useServiceByHandle = (handle: string): IService | undefined => (
  useFilteredServices([])?.find((service: IService) => service.handle === handle)
)

export const useCheckServiceOnline = (service ?: IService) => {
  const [online, setOnline] = useState<boolean>(false)

  const url = service?.techInfo.resourceLinks.find(l => l.type === "swagger")?.url
  || service?.techInfo.resourceLinks.find(l => l.type === "backend")?.url

  useEffect(
    () => {
      isRequestOK(url || '')
        .then(setOnline)
    },
    [service, url]
  )

  return online
}

