import React, {CSSProperties} from 'react';
import {INDUSTRY_BG_IMAGE} from 'config/image'
import {Link} from 'react-router-dom'

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  label: string
  className?: string
  img?: string
  active?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const ImgMenuButton: React.FC<Props> = ({
   label, className,
   onClick,
   active,
   img,
   ...buttonProps
 }) => {
  if (!label) return null

  const backGroundImageUrl = INDUSTRY_BG_IMAGE[label]
  const inlineStyle: CSSProperties =  backGroundImageUrl
    ? {
    backgroundImage: `url(${backGroundImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  } : {}

  return (
    <Link to="/" className={className}>
    <button
      {...buttonProps}
      value={label}
      style={inlineStyle}
      className={`w-full h-32 bg-blue-800 text-white text-xl hover:font-extrabold font-bold py-1 px-3 mt-2 mb-4 rounded outline-none`}
      onClick={onClick}
    >
      {label}
    </button>
    </Link>
  )
}

export default ImgMenuButton