import React from 'react';
import {OptionsType, OptionTypeBase} from "react-select";
import ReactSelect from "react-select/creatable";
import {FieldHelperProps, FieldInputProps, FieldMetaProps} from 'formik/dist/types'
import {ValueType} from 'react-select/src/types'
import {Props} from 'react-select/base'

interface SingleCreatableSelectProps extends Props<OptionTypeBase> {
  label: string
  name: string
  className?: string
  options?: OptionsType<OptionTypeBase>
  formik: {
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
  }
}

const SingleCreatableSelect: React.FC<SingleCreatableSelectProps> = ({label, name, className, formik, options, ...selectProps}) => {

  const formikValue = formik.getFieldProps(name).value
  const formikSetValue = formik.getFieldHelpers(name).setValue
  const {error} = formik.getFieldMeta(name)

  const value = {label: formikValue, value: formikValue}
  const handleChange = (value: ValueType<OptionTypeBase>) => {
    // @ts-ignore
    console.log("change", value.value)
    // @ts-ignore
    formikSetValue(value.value)
  }
  const handleInputChange = (value: string) => {
    console.log("input", value)
    value && formikSetValue(value)
  }


  return (
    <div className={className}>
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {label}
      </label>
      <ReactSelect
        {...selectProps}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        formatCreateLabel={inputValue => <span>Ajouter de {label} : {inputValue}</span>}
        value={value}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  )
}

export default SingleCreatableSelect