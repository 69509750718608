import React from 'react';
import Filter, {
  FilterType,
  getIndustries,
  getKinds,
  getProjects,
  getThemes,
  getTop10Tags,
  useFilteredServices
} from 'common/Filter'
import ImgMenuButton from 'components/button/ImgMenuButton'
import Pill from 'components/button/Pill'
import MenuButton from 'components/button/MenuButton'

interface Props {
  filters: Array<Filter>
  setFilter: (filters: Filter[]) => void
}

const Menu: React.FC<Props> = ({filters, setFilter}) => {
  const data = useFilteredServices(filters)
  if(!Array.isArray(data)){
    return  <div className="flex-grow overflow-x-hidden p-2"/>
  }

  const searchInput = document.getElementById('search') as HTMLInputElement

  const handleFilterClick = (type: FilterType) => (e: React.MouseEvent) => {
    const {value} = e.target as HTMLButtonElement

    const isSelected = !!filters.find(filter => filter.value === value)
    if (isSelected) {
      setFilter([...filters.filter(filter => filter.value !== value && filter.type!== "SEARCH")])
    } else {
      setFilter([...filters.filter(filter => filter.type!== "SEARCH"), {type, value}])
    }
    searchInput.value =''
  }

  const isActive = (value: string) => !!filters.find(filter => filter.value === value)

  const industryMenuButtons = getIndustries(data).map(industry => (
    <ImgMenuButton key={industry}
                   id={industry.substring(0,3)}
                   label={industry}
                   className="mx-auto w-full"
                   active={isActive(industry)}
                   onClick={handleFilterClick(FilterType.INDUSTRY)}/>))

  const projectMenuButtons = getProjects(data).map(project => (
    <MenuButton key={project}
                label={project}
                className="mx-1"
                active={isActive(project)}
                onClick={handleFilterClick(FilterType.PROJECT)}/>))

  const kindMenuButtons = getKinds(data).map(kind => (
    <Pill key={kind}
          label={kind}
          className="mx-1"
          active={isActive(kind)}
          onClick={handleFilterClick(FilterType.KIND)}/>))

  const themeMenuButtons = getThemes(data).map(theme => (
    <Pill key={theme}
          label={theme}
          className="mx-1"
          active={isActive(theme)}
          onClick={handleFilterClick(FilterType.THEME)}/>))

  const tagsMenuButtons = getTop10Tags(data).map(tags => (
    <Pill key={tags}
          label={tags}
          className="mx-1"
          active={isActive(tags)}
          onClick={handleFilterClick(FilterType.TAG)}/>))

  const renderStep = [industryMenuButtons,
    projectMenuButtons]

  const mainFilters = filters.filter(f => [FilterType.INDUSTRY, FilterType.PROJECT].includes(f.type))
  return (
    <div className="flex-grow overflow-x-hidden p-2">
      {renderStep[mainFilters.length]}
      {mainFilters.length >= 2 &&
      <>
        <h3 className="text-xl font-bold my-3 ml-2 underline ">Types :</h3>
        {kindMenuButtons}
        <h3 className="text-xl font-bold my-3 ml-2 underline ">Thèmes :</h3>
        {themeMenuButtons}
        <h3 className="text-xl font-bold my-3 ml-2 underline ">Top 10 Tags :</h3>
        {tagsMenuButtons}
      </>}
    </div>
  )
}

export default Menu