import React from 'react';
import {ResourceLink} from 'common/Service'
import {iconElement} from 'config/image'

interface Props {
  link: ResourceLink
}

const UsefulLink: React.FC<Props> = ({link}) => (
  <a href={link.url} target="_blank" rel="noreferrer noopener" className="pr-2 py-1 font-normal bg-gray-500 my-1 mr-4 rounded-lg inline-block">
    {iconElement[link.type]}
  </a>
)

export default UsefulLink