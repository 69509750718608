import React, {useEffect, useRef} from 'react';
import Filter, {FilterType} from 'common/Filter'

interface Props {
  filters: Array<Filter>
  setFilter: (filters: Filter[]) => void
}

const Search: React.FC<Props> = ({filters, setFilter}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if(filters
      .find(f => [FilterType.TAG, FilterType.THEME, FilterType.KIND].includes(f.type))){
      inputRef.current!.value ='' //Reset the search bar with DOM manipulation
    }
  }, [filters])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchFilters = e.target.value
      .split(' ')
      .filter(w => w)
      .map(word => ({type: FilterType.SEARCH, value: word}))

    const menuFilters = filters.filter(f => [FilterType.INDUSTRY, FilterType.PROJECT].includes(f.type))
    setFilter([...menuFilters, ...searchFilters])
  }
  return (
    <div>
      <label className="block text-gray-700 text-sm font-bold m-2" htmlFor="search">
        Rechercher :
      </label>
      <input
        ref={inputRef}
        onChange={handleSearchChange}
        className="shadow appearance-none border rounded-lg w-11/12 block mx-auto mb-3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="search" type="text" placeholder="nom, tags, description, ..."/>
    </div>
  )
}

export default Search