import request from 'graphql-request'
import {Variables} from 'graphql-request/dist/src/types'

const {
  REACT_APP_DOMAIN_URL = 'http://localhost:5000/api/v1'
} = process.env

export const DOMAIN_URL = REACT_APP_DOMAIN_URL


export const fetcherWithToken = async (url: string, token: string) => {
  const response = await fetch(url, {
    method: 'get',
    headers: new Headers({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    })
  })
  if(!response.ok) throw new Error(`HttpError: ${response.status} ${response.statusText}`)
  return response.json()
}

export const fetcher = (url: string) => fetch(url)
  .then(res => res.json())


export const graphQlFetcher = (query: string) => request('http://localhost:5000/graphql', query)

export const graphQlMutation = (query: string, variables: Variables) => request('http://localhost:5000/graphql', query, variables)