import React from 'react';
import Filter, {FilterType} from 'common/Filter'
import {Link} from 'react-router-dom'

interface Props {
  className?: string
  filters: Array<Filter>
  setFilter: (filters: Filter[]) => void
}

const BreadCrumb: React.FC<Props> = ({filters, setFilter, className}) => {
  const industryFilter = filters.find(f => f.type === FilterType.INDUSTRY)
  const projectFilter = filters.find(f => f.type === FilterType.PROJECT)
  const links: Array<JSX.Element | undefined> = [
    <Link className=""
          key="home-button"
          to="/"
          onClick={() => setFilter([])}>
      <svg className="mb-1 w-10 h-10 inline-block" fill="currentColor" viewBox="0 0 20 20">
        <path
          d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"/>
      </svg>
    </Link>,
    industryFilter
    && <Link className="hover:underline hover:font-bold"
             key="industry-button"
             to="/"
             onClick={() => {
               if (filters.length>1) {
                 setFilter(filters.slice(0, filters.length - 1))
               }
             }}
    >
      {industryFilter!.value}
    </Link>,
    projectFilter
    && <Link className="hover:underline hover:font-bold"
             key="project-button"
             to="/"
    >
      {projectFilter!.value}
    </Link>
  ].filter(f => !!f)

  const path = links.map((e, index) => [e, <span key={index} className=""> / </span>]).flat()
  path.pop()

  return (
    <div className={className}>
      {path}
    </div>
  )
}

export default BreadCrumb 