import React from 'react';
import SimpleCard from 'components/card/SimpleCard'
import {IService} from 'common/Service'
import Definition from 'components/definition/Definition'

interface Props {
  service: IService
}

const ProjectInfo: React.FC<Props> = ({service}) => {
  const {irtInfo : {agavRef, contributors, industry, project, theme}} = service
  return (
    <SimpleCard title="Information Projet">
      <dl>
        <Definition term="Projet :">
          <span>{project}</span>
        </Definition>
        <Definition term="Collaborateurs :" hide={!contributors.length}>
          {contributors.join(', ')}
        </Definition>
        <Definition term="Thematique :">
          {theme}
        </Definition>
        <Definition term="Industrie :">
          {industry}
        </Definition>
        <Definition term="Réference AGAV :">
          {agavRef}
        </Definition>
      </dl>
    </SimpleCard>

  )
}

export default ProjectInfo