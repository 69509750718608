import React from 'react';

interface Props {
  className?: string
  title: string | JSX.Element
  topRightButton?: JSX.Element
}

const SimpleCard: React.FC<Props> = ({className, title, topRightButton, children}) => (
  <section className={`${className || ""} rounded overflow-hidden shadow-lg border p-4`}>
    <h4 className="text-3xl mb-3 text-center">{title}</h4> {topRightButton && <span className="float-right -mt-12">{topRightButton}</span>}
    {children}
  </section>
)

export default SimpleCard;