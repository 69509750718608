import React from 'react';
import {useHistory, useParams} from 'react-router-dom'
import {createNewService, IService, useServiceByHandle} from 'common/Service'
import {useFormik} from 'formik'

import {formikProps, nameToHandle, ServiceValidation} from 'layout/main/form/validation'
import {INDUSTRY, KIND, mapToOptions} from 'config/option'

import Input from 'components/form/Input'
import TextArea from 'components/form/TextArea'
import MultiSelect from 'components/form/MultiSelect'
import CreatableSelect from 'components/form/SingleCreatableSelect'
import Select from 'components/form/Select'
import ResourceLinkInput from 'layout/main/form/ResourceLinkInput'
import SwaggerReact from 'components/swagger/SwaggerReact';
import {saveService, useServices} from 'domain/services'
import {SWAGGER_INITIAL_VALUE} from 'common/imgUtils'
import {OptionTypeBase} from 'react-select'
import {useAlert} from 'components/alert/AlertManager'

const ServiceForm: React.FC = () => {
  const {handle} = useParams()
  const history = useHistory()
  const service = useServiceByHandle(handle)
  const services = useServices()

  const [addAlert] = useAlert()

  const formik = useFormik<IService>({
    enableReinitialize: true,
    initialValues: service || createNewService(),
    validationSchema: ServiceValidation,
    onSubmit: async (values) => saveService(values, handle)
      .then((res : IService) => addAlert({title:'Sauvegarde effectuée', text: `Le service ${res.name} a bien été sauvegardé`, type: 'success'}))
      .catch(e => addAlert({title:'Sauvegarde non effectuée', text:e.message, type: 'danger'}))
      .then(() => history.push(`/${values.handle || handle }`))

  })

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
    formik.setFieldValue("handle", nameToHandle(e.target.value))
  }

  const handleIndustryChange = () => formik.setFieldValue("irtInfo.project", undefined)

  const handleKindChange = (selected: OptionTypeBase) => {
    if (selected.value && !formik.values.swagger) {
      formik.setFieldValue("swagger", SWAGGER_INITIAL_VALUE)
    } else {
      formik.setFieldValue("swagger", undefined)
    }
  }

  return (
    <form className="py-3 px-5 lg:px-20 xl:px-32he " onSubmit={formik.handleSubmit}>
      <h1
        className="col-span-2 mb-6 text-4xl underline font-semibold">{service ? "Modification du service" : "Création d'un service"}</h1>
      <div className="flex justify-between ">
        <Input label="Nom"
               className="mb-3 mr-5 flex-grow"
               {...formikProps(formik, 'name')}
               onChange={handleNameChange}
        />
        <Input label="Handle" className="mb-3 flex-grow" {...formikProps(formik, 'handle')}/>
      </div>
      <Input label="Icon" className="mb-3 flex-grow" {...formikProps(formik, 'icon')}/>
      <TextArea label="Description" className="mb-3" rows={5} {...formikProps(formik, 'description')}/>
      <div className="flex justify-between ">
        <Select
          name="kind"
          className="mb-3 mr-5 flex-grow"
          label="Type"
          options={KIND}
          onChangeCallBack={handleKindChange}
          formik={formik}
        />
        <Input label="Version" className="mb-3 flex-grow" {...formikProps(formik, 'version')}/>
      </div>
      {formik.values.kind === "API" && (
        <div className="flex">
          <TextArea
            label="Swagger"
            className="mb-3 flex-grow"
            rows={30}
            textAreaClassName="text-white bg-gray-800"
            {...formikProps(formik, 'swagger')}
          />
          <SwaggerReact className="w-1/2 h-80vh overflow-scroll" swaggerContent={formik.values.swagger}/>
        </div>
      )}
      {formik.values.kind === "DASHBOARD" && (
        <Input label="Url du Dashboard" className="mb-3" {...formikProps(formik, 'dashboard')}/>
      )}

      <h4 className="mb-6 mt-10 text-2xl underline font-semibold">Informations Projet</h4>
      <div className="flex justify-between ">
        <Select
          name="irtInfo.industry"
          className="mb-3 mr-5 flex-grow"
          label="Industrie"
          options={INDUSTRY}
          onChangeCallBack={handleIndustryChange}
          formik={formik}
        />
        <Select
          name="irtInfo.project"
          className="mb-3 flex-grow"
          label="Projet"
          options={INDUSTRY
            .find((option) => option.value === formik.values.irtInfo.industry)?.projects || []}
          formik={formik}
        />
      </div>
      <div className="flex justify-between ">
        <CreatableSelect
          name="irtInfo.theme"
          className="mb-3 mr-5 flex-grow"
          label="Thème"
          options={[...new Set(services.data?.map(s => s.irtInfo.theme).flat())].map(mapToOptions)}
          formik={formik}
          placeholder="Veuillez saisir le thème du service ..."
        />
        <Input label="Réference Agav" className="mb-3 flex-grow" {...formikProps(formik, 'irtInfo.agavRef')}/>
      </div>
      <MultiSelect
        name="irtInfo.contributors"
        className="mb-3"
        label="Contributeurs"
        formik={formik}
        options={[...new Set(services.data?.map(s => s.irtInfo.contributors).flat())].map(mapToOptions)}
        placeholder="Veuillez saisir les contributeurs du service ..."
      />

      <h4 className="mb-6 mt-10 text-2xl underline font-semibold">Informations Techniques</h4>
      <MultiSelect
        name="techInfo.technologies"
        className="mb-3"
        label="Technologies"
        options={[...new Set(services.data?.map(s => s.techInfo.technologies).flat())].map(mapToOptions)}
        formik={formik}
        placeholder="Veuillez saisir les technologies du service ..."
      />

      <MultiSelect
        name="techInfo.dependencies"
        className="mb-3"
        label="Dépendances"
        formik={formik}
        placeholder="Veuillez saisir les services qui dépendent de ce service ..."
      />

      <ResourceLinkInput formik={formik} className="mb-5"/>

      <MultiSelect
        name="tags"
        className="mb-8"
        label="Tags"
        formik={formik}
        menuPlacement="top"
        options={[...new Set(services.data?.map(s => s.tags).flat())].map(mapToOptions)}
        placeholder="Veuillez saisir les mots-clés qui représentent ce service ..."
      />

      <button
        className="bg-blue-500 hover:bg-blue-700 float-right text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-6"
        type="submit"
        id="save-form"
      >
        {handle ? "Modifier" : "Créer"}
      </button>
    </form>
  )
}

export default ServiceForm 