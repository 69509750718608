import React from 'react';
import {Link} from 'react-router-dom'
import {handleSourceImg} from 'common/imgUtils'
import {IService} from 'common/Service'
import {deleteService} from 'domain/services'

interface Props {
  service: IService
  canEdit?: boolean
  canDelete?: boolean
}

const SumUpCard: React.FC<Props> = ({service, canEdit, canDelete}) => {
  const tags = service.tags.map(tag =>
    <span key={service.handle + tag}
          className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-1">
      {tag}
    </span>)
  const {handle} = service

  return (
    <div
      className="border border-gray-300 col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 2xl:col-span-3 bg-white rounded p-4 flex flex-col justify-between shadow-lg">
      <div>
        <div className="flex justify-between">
          {/* Symbole + King of Service API || DASHBOARD */}
          <p className="text-sm text-gray-600 flex items-center">
            {service.kind === 'API'
              ? <svg className="h-5 w-5 mr-2" viewBox="64 64 896 896" focusable="false" data-icon="cluster" width="1em"
                     height="1em"
                     fill="currentColor" aria-hidden="true">
                <path
                  d="M888 680h-54V540H546v-92h238c8.8 0 16-7.2 16-16V168c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16v264c0 8.8 7.2 16 16 16h238v92H190v140h-54c-4.4 0-8 3.6-8 8v176c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V688c0-4.4-3.6-8-8-8h-54v-72h220v72h-54c-4.4 0-8 3.6-8 8v176c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V688c0-4.4-3.6-8-8-8h-54v-72h220v72h-54c-4.4 0-8 3.6-8 8v176c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V688c0-4.4-3.6-8-8-8zM256 805.3c0 1.5-1.2 2.7-2.7 2.7h-58.7c-1.5 0-2.7-1.2-2.7-2.7v-58.7c0-1.5 1.2-2.7 2.7-2.7h58.7c1.5 0 2.7 1.2 2.7 2.7v58.7zm288 0c0 1.5-1.2 2.7-2.7 2.7h-58.7c-1.5 0-2.7-1.2-2.7-2.7v-58.7c0-1.5 1.2-2.7 2.7-2.7h58.7c1.5 0 2.7 1.2 2.7 2.7v58.7zM288 384V216h448v168H288zm544 421.3c0 1.5-1.2 2.7-2.7 2.7h-58.7c-1.5 0-2.7-1.2-2.7-2.7v-58.7c0-1.5 1.2-2.7 2.7-2.7h58.7c1.5 0 2.7 1.2 2.7 2.7v58.7zM360 300a40 40 0 1080 0 40 40 0 10-80 0z"/>
              </svg>
              : <svg className="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd"
                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                      clipRule="evenodd"/>
              </svg>}
            {service.kind}
          </p>

          {/* Edit and Delete Button*/}
          <div className="text-gray-500 -mt-3 -mr-2">

            {canEdit &&
            <Link to={`/form/${service.handle}`}>
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5 inline mr-3 hover:text-gray-800">
                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"/>
                <path fillRule="evenodd"
                      d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                      clipRule="evenodd"/>
              </svg>
            </Link>}

            {canDelete &&
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5 inline hover:text-red-700"
                 onClick={() => deleteService(handle)}>
              <path fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"/>
            </svg>}

          </div>
        </div>

        {/* Service Name and image and version if there is one */}
        <div className="flex justify-start">
          {service.icon && <img className="w-10 h-10 mr-2" src={handleSourceImg(service.icon)} alt="icon"/>}
          <Link to={`/${service.handle}`} className="text-gray-900 font-bold text-xl underline">{service.name}</Link>
          {service.version &&
          <span className="h-4 ml-3 rounded-full bg-gray-500 text-xs text-white px-2">{service.version}</span>}
        </div>
        <p className="text-gray-700 text-base text-ellipsis">{service.description}</p>
      </div>
      <div className="py-4">
        {tags}
      </div>
    </div>
  )
}

export default SumUpCard