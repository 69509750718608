import React from 'react';

interface DefinitionProps {
  term: string
  hide?: boolean
}

const Definition: React.FC<DefinitionProps> = ({term,hide, children}) => {
  if (!children || hide) {
    return null
  }
  return (
    <>
      <dt className="font-semibold inline">
        {term}
      </dt>
      <dd className="font-thin ml-4">
        {children}
      </dd>
    </>
  )
}

export default Definition