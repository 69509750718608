import React from 'react';
import {Link} from 'react-router-dom'

interface Props {
 className?: string
}

const AddService: React.FC<Props> = ({className}) => {
  return (
    <Link to="/form" className={className}>
      <button className="p-2 text-white bg-blue-900 font-semibold rounded-lg mb-3 text-xl" >Ajouter un service</button>
    </Link>
  )
}

export default AddService 