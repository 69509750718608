import React from 'react';

interface BadgeProps {
  label: string
  className?: string
  active?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const Pill: React.FC<BadgeProps> = ({
    label,
    className,
    active,
    onClick
  }) => {
  if (!label) return null
  return (
    <button
      value={label}
      className={`${active ? "bg-blue-700" : "bg-blue-100 text-blue-600"} hover:bg-blue-700 hover:text-white border-blue-300 border-double border-2 text-white text-sm font-bold py-1 px-3 mb-1 rounded-full outline-none ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default Pill