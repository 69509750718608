import React from 'react';

interface Props {
  label: string
  className?: string
  active?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const MenuButton: React.FC<Props> = ({
   label, className,
   onClick,
   active
 }) => {
  if (!label) return null
  return (
    <button
      value={label}
      className={`${active ? "bg-blue-800 text-white" : "bg-white text-blue-800 border-blue-800 border-solid border-2 hover:bg-blue-800 hover:text-white"} text-xl font-bold py-1 px-3 mt-2 mb-4 rounded outline-none ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export default MenuButton
