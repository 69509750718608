import useSWR from 'swr'
import {IService} from 'common/Service'
import {DOMAIN_URL, fetcher, fetcherWithToken} from 'domain/config'
import {useKeycloak} from '@react-keycloak/web'
import {useEffect} from 'react'
import {keycloak, ParsedToken} from 'config/auth'
import {useAlert} from 'components/alert/AlertManager'

export const useAllServices = () => useSWR<Array<IService>, string>(`${DOMAIN_URL}/services`, fetcher)

export const useServices = () => {
  const {keycloak} = useKeycloak()
  const [addAlert] = useAlert()
  const token = keycloak?.token
  const swrHook = useSWR<Array<IService>, string>(
    [`${DOMAIN_URL}/services`, token],
    fetcherWithToken,
    {
      initialData: [],
      revalidateOnFocus: false,
      revalidateOnMount: false,
      shouldRetryOnError: false
    }
  )

  useEffect(() => {
    if (token && !swrHook.isValidating) {
      swrHook.mutate()
      const idTokenParsed = keycloak?.idTokenParsed as ParsedToken
      if (!token || !idTokenParsed.groups || idTokenParsed.groups.length === 0) {
        addAlert({type: 'warning', title: 'Problème avec le token', text: JSON.stringify(idTokenParsed)})
      }
    }

  }, [token])

  return swrHook
}

export const deleteService = async (handle: string) => {
  const response = await fetch(`${DOMAIN_URL}/services/${handle}`, {
    method: 'DELETE',
    headers: new Headers({
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json'
    })
  })
  if (!response.ok) throw new Error(`HttpError: ${response.status} ${response.statusText}`)
  return response.json()
}

export const saveService = async (values: IService, handle: string) => {
  const response = await fetch(`${DOMAIN_URL}/services${handle ? '/' + handle : ''}`, {
    method: handle ? 'PUT' : 'POST',
    body: JSON.stringify(values),
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + keycloak.token,
      'Content-Type': 'application/json'
    }
  })
  if (!response.ok) throw new Error(`HttpError: ${response.status} ${response.statusText}`)
  return response.json()
}

export const isRequestOK = async (url: string): Promise<boolean> =>
  fetch(url)
    .then((res) => res.status === 200)
    .catch(e => false)


