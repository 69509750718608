import React from 'react';
import {FieldHelperProps, FieldInputProps, FieldMetaProps} from 'formik/dist/types'
import {ResourceLink} from 'common/Service'
import Input from 'components/form/Input'
import {formikProps} from 'layout/main/form/validation'
import Select from 'components/form/Select'
import {LINK_TYPES} from 'config/option'

interface Props {
  className?: string
  formik: {
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
  }
}

const ResourceLinkInput: React.FC<Props> = ({formik, className}) => {
  const formikValue: Array<ResourceLink> = formik.getFieldProps('techInfo.resourceLinks').value
  const formikSetValue = formik.getFieldHelpers('techInfo.resourceLinks').setValue


  const addResourceLink = () => formikSetValue([...formikValue, {}])

  const deleteResourceLink = (indexR : number) => () => formikSetValue(formikValue.filter((value, index) => index !== indexR))

  const inputs: Array<JSX.Element> = formikValue.map((resourceLink, index) => (
    <div className="flex" key={index}>
      <Select className="w-1/6 mr-5" name={`techInfo.resourceLinks[${index}].type`} options={LINK_TYPES} formik={formik} />
      <Input className="flex-grow mb-3" {...formikProps(formik, `techInfo.resourceLinks[${index}].url`)} />
      <button type="button" className="ml-2 w-5 h-5 mt-2" onClick={deleteResourceLink(index)}>
        <svg fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"/></svg>
      </button>
    </div>)
  )

  return (
    <div className={className}>
      <label className="block text-gray-700 text-sm font-bold mb-3">
        Liens utiles
      </label>
      {inputs}
      <button type="button" className="p-2 bg-gray-700 rounded-lg text-white" onClick={addResourceLink}>Ajouter une url</button>
    </div>
  )
}

export default ResourceLinkInput