import React, {useEffect, useState} from 'react';

const colors = {
  info: "blue",
  success: "green",
  warning: "orange",
  danger: "red"
}

export interface AlertProps {
  title: string
  type: "info" | "warning" | "danger" | "success"
  text: string
}

const Alert: React.FC<AlertProps> = ({title, text, type}) => {
  const [hidden, setHidden] = useState(false)

  useEffect(() => {

    setTimeout(() => setHidden(true), 5000)
  }, [])

  if (hidden) return null
  const color: string = colors[type]
  return (
    <div
      className={`bg-${color}-100 border-l-4 border-${color}-500 text-${color}-700 p-4 shadow-1 my-1 min-w-1/2 z-10 ${hidden &&
      "hidden"}`}
      role="alert"
    >
      <div className="flex">
        <p className="flex-grow font-bold">{title}</p>
        <button onClick={() => setHidden(true)}>x</button>
      </div>
      <p>{text}</p>
    </div>
  )
}

export default Alert
