import React from 'react';

interface Props extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>{
  label: string
  className?: string
  textAreaClassName?: string
  error?: string
}

const TextArea: React.FC<Props> = ({label, className, error,textAreaClassName,  ...inputProps}) => (
  <div className={className}>
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={inputProps?.id}>
      {label}
    </label>
    <textarea
      className={`${error ? 'border-red-500 mb-1' : ''} shadow appearance-none border rounded w-full py-2 px-3 focus:outline-none focus:shadow-outline ${textAreaClassName}`}
      {...inputProps} />
    {error && <p className="text-red-500 text-xs italic">{error}</p>}
  </div>
)

export default TextArea 