import React from 'react';

import SumUpCard from "layout/main/home/SumUpCard";
import Filter, {useFilteredServices} from 'common/Filter'
import BreadCrumb from 'layout/sidebar/BreadCrumb'
import {useRights} from 'config/auth'

interface Props {
  filters: Array<Filter>
  setFilter: (filters: Filter[]) => void
}


const ServiceGrid: React.FC<Props> = ({filters, setFilter}) => {
  const canEdit = useRights()[1]

  const data = useFilteredServices(filters)
  if(!Array.isArray(data)){
    return  <div className="mt-3 text-center"> No Services</div>
  }

  const allServices = data
    ?.map(service => (
      <SumUpCard
        canEdit={canEdit}
        canDelete={canEdit} // May Change
        key={service.handle} service={service}/>)
    )

  return (
    <>
      <BreadCrumb className="text-lg text-white bg-gray-800 text-center font-sans" filters={filters}
                  setFilter={setFilter}/>
      <div className="m-10 grid grid-cols-12 gap-3 mt-3">
        {allServices}
      </div>
    </>
  )
}

export default ServiceGrid