import React from 'react';

interface Props {
  className?: string
}

const GitLabSVG: React.FC<Props> = ({className}) => (
  <svg viewBox="0 0 210 194" className={className}><path
    d="M105.0614,193.655 L105.0614,193.655 L143.7014,74.734 L66.4214,74.734 L105.0614,193.655 L105.0614,193.655 Z"
    fill="#E24329" className="logo-svg-shape logo-dark-orange-shape"></path>
    <path d="M105.0614,193.6548 L66.4214,74.7338 L12.2684,74.7338 L105.0614,193.6548 Z" fill="#FC6D26"
          className="logo-svg-shape logo-orange-shape"></path>
    <path
      d="M12.2685,74.7341 L12.2685,74.7341 L0.5265,110.8731 C-0.5445,114.1691 0.6285,117.7801 3.4325,119.8171 L105.0615,193.6551 L12.2685,74.7341 Z"
      fill="#FCA326" className="logo-svg-shape logo-light-orange-shape"></path>
    <path
      d="M12.2685,74.7342 L66.4215,74.7342 L43.1485,3.1092 C41.9515,-0.5768 36.7375,-0.5758 35.5405,3.1092 L12.2685,74.7342 Z"
      fill="#E24329" className="logo-svg-shape logo-dark-orange-shape"></path>
    <path d="M105.0614,193.6548 L143.7014,74.7338 L197.8544,74.7338 L105.0614,193.6548 Z" fill="#FC6D26"
          className="logo-svg-shape logo-orange-shape"></path>
    <path
      d="M197.8544,74.7341 L197.8544,74.7341 L209.5964,110.8731 C210.6674,114.1691 209.4944,117.7801 206.6904,119.8171 L105.0614,193.6551 L197.8544,74.7341 Z"
      fill="#FCA326" className="logo-svg-shape logo-light-orange-shape"></path>
    <path
      d="M197.8544,74.7342 L143.7014,74.7342 L166.9744,3.1092 C168.1714,-0.5768 173.3854,-0.5758 174.5824,3.1092 L197.8544,74.7342 Z"
      fill="#E24329" className="logo-svg-shape logo-dark-orange-shape"></path>
  </svg>
)

export default GitLabSVG