import React from 'react';
// @ts-ignore
import SwaggerUI from 'swagger-ui-react'
import {JSONorYAMLParse} from 'common/imgUtils'
import {useAlert} from 'components/alert/AlertManager'
import {HIT_AMOUNT} from 'config/swagger'

interface Props {
  swaggerContent?: string
  className?: string
}

interface SwaggerUse {
  hit: number
  lastModified: Date
}

function addDays(date: Date, days: number) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const SwaggerReact: React.FC<Props> = ({swaggerContent, className}) => {
  const [addAlert] = useAlert()

  const reqInt = (req: Request) => {
    const baseUrl = req.url.split('://')[1].split('/')[0]
    const swaggerUse: SwaggerUse = localStorage.getItem(baseUrl) && JSON.parse(localStorage.getItem(baseUrl)!)
    if (swaggerUse) {
      if (swaggerUse.hit > (+HIT_AMOUNT-1) && new Date() < addDays(swaggerUse.lastModified, 1)) {
        addAlert({
          type: 'danger',
          text: 'Vous avez dépassé votre quota d\'interaction avec  l\'API ',
          title: 'API verrouillé'
        })
        throw new Error('Locked API after many calls')
      } else if (swaggerUse.hit > (+HIT_AMOUNT-1)) { // When Hit expired resets
        const newSwaggerUse: SwaggerUse = {hit: 1, lastModified: new Date()}
        localStorage.setItem(baseUrl, JSON.stringify(newSwaggerUse))
      } else {
        const newSwaggerUse: SwaggerUse = {hit: swaggerUse.hit + 1, lastModified: new Date()}
        localStorage.setItem(baseUrl, JSON.stringify(newSwaggerUse))
      }
    } else {
      localStorage.setItem(baseUrl, JSON.stringify({hit: 1, lastModified: new Date()}))
    }
    return req
  }

  try {
    const specObj = JSONorYAMLParse(swaggerContent)
    return (
      <div className={className}>
        <SwaggerUI spec={specObj} requestInterceptor={reqInt}/>
      </div>
    )
  } catch (e) {
    return (
      <span>Ce n'est pas un objet JSON ou YAML valide</span>
    )

  }


}

export default SwaggerReact 