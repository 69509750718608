import React from 'react';

import ReactSelect, {OptionsType, StylesConfig} from "react-select";
import {OptionTypeBase} from 'react-select/src/types'
import {Props} from 'react-select/base'

import {FieldHelperProps, FieldInputProps, FieldMetaProps} from 'formik/dist/types'

interface SelectProps extends Props<OptionTypeBase> {
  label?: string
  name: string
  className?: string
  options: OptionsType<OptionTypeBase>
  formik: {
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
  }
  onChangeCallBack?: (optionValue: any) => void
}

const Select: React.FC<SelectProps> = ({label, name, className, formik, options, onChangeCallBack,...selectProps}) => {
  const formikValue = formik.getFieldProps(name).value
  const formikSetValue = formik.getFieldHelpers(name).setValue
  const values: OptionTypeBase = {label: formikValue, value: formikValue}
  const {error} = formik.getFieldMeta(name)

  const handleChange = (optionValue: any) => {
    formikSetValue(optionValue?.value)
    onChangeCallBack?.(optionValue)
  }

  const styles : StylesConfig = {
    control: (base, state) => ({
      ...base,
      borderColor: error && '#f56565'
    })
  }

  return (
    <div className={className}>
      {label && <label className="block text-gray-700 text-sm font-bold mb-2">
        {label}
      </label>}
      <ReactSelect
        styles={styles}
        {...selectProps}
        onChange={handleChange}
        options={options}
        value={values}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  )
}

export default Select 