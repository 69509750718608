import {IService} from 'common/Service'
import {useServices} from 'domain/services'

export default interface Filter {
  type: FilterType
  value: string
}

export enum FilterType {
  PROJECT = "PROJECT",
  KIND = "KIND",
  THEME = "THEME",
  INDUSTRY = "INDUSTRY",
  TAG = "TAG",
  HANDLE = "HANDLE",
  SEARCH = "SEARCH"
}

export const useFilteredServices = (filters: Array<Filter>): IService[] | undefined => {
  const {data, error} = useServices()
  error && console.error(error)
  if (!filters?.length) return data
  else {
    const searchWords = filters.filter(f => f.type === FilterType.SEARCH).map(f => f.value)
    const projects = filters.filter(f => f.type === FilterType.PROJECT).map(f => f.value)
    const kinds = filters.filter(f => f.type === FilterType.KIND).map(f => f.value)
    const industries = filters.filter(f => f.type === FilterType.INDUSTRY).map(f => f.value)
    const themes = filters.filter(f => f.type === FilterType.THEME).map(f => f.value)
    const tags = filters.filter(f => f.type === FilterType.TAG).map(f => f.value)
    const handle = filters.find(f => f.type === FilterType.HANDLE)?.value
    return data?.filter((s: IService) => (
        // Menu Filters
      !projects.length || projects.includes(s.irtInfo.project))
      && (!industries.length || industries.includes(s.irtInfo.industry))
      // Pills Filters
      && ((!kinds.length || kinds.includes(s.kind))
        && (!themes.length || themes.includes(s.irtInfo.theme))
        && (!handle || s.handle === handle)
        && (!tags.length || tags.every(t => s.tags.includes(t)))
      )
      // SearchBar Filters
      && (!searchWords.length
        || !![
          s.name, s.tags.join(' '), s.techInfo.technologies.join(' '),
          s.kind, s.handle, s.irtInfo.project, s.irtInfo.industry, s.description
        ].filter(v => searchWords.some(w => v.includes(w))).length
      )
    )
  }
}

export const getProjects = (services?: Array<IService>): Array<string> => [...new Set<string>(services?.map(service => service.irtInfo.project))]
export const getKinds = (services?: Array<IService>): Array<string> => [...new Set<string>(services?.map(service => service.kind))]
export const getIndustries = (services?: Array<IService>): Array<string> => [...new Set<string>(services?.map(service => service.irtInfo.industry))]
export const getThemes = (services?: Array<IService>): Array<string> => [...new Set<string>(services?.map(service => service.irtInfo.theme))]
export const getTags = (services?: Array<IService>): Array<string | undefined> => services?.map(service => service.tags)?.flat()?.sort() || []

export const getWeightedTags = (array: Array<string | undefined>) => {
  let counts = new Map<string, number>()
  for (let i = 0; i < array.length; i++) {
    // @ts-ignore
    counts[array[i]] = (counts[array[i]] + 1) || 1;
  }
  return counts
}

export const getTop10Tags = (services?: Array<IService>) => Object.entries(getWeightedTags(getTags(services)))
  .sort((a, b) => b[1] - a[1])
  .map(x => x[0]).slice(0, 10)

