import React from 'react';
import SimpleCard from 'components/card/SimpleCard'
import {IService} from 'common/Service'
import Definition from 'components/definition/Definition'
import UsefulLink from 'components/button/UsefulLink'

interface Props {
  service: IService
}

const TechInfo: React.FC<Props> = ({service: {techInfo: {dependencies, resourceLinks, technologies}}}) => (
  <SimpleCard title="Information Technique">
    <dl>
      <Definition term="Language :">
        {technologies.join(', ')}
      </Definition>
      <Definition term="Dépendances :">
        {dependencies?.join(', ')}
      </Definition>
      <Definition term="Lien utiles :" hide={!resourceLinks.length}>
        {resourceLinks.map(link => <UsefulLink key={JSON.stringify(link)} link={link}/>)}
      </Definition>
    </dl>
  </SimpleCard>
)


export default TechInfo