import * as Yup from "yup"


export const ServiceValidation = Yup.object().shape(
  {
    name: Yup.string()
      .required('Le nom du service est requis'),
    handle: Yup.string()
      .uppercase('Le handle doit être en minuscule')
      .matches(/^[a-zA-Z0-9_-]*$/, "Le handle ne peut être utilisé dans une url")
      .required('Le handle du service est requis'),
    icon: Yup.string().optional().nullable(),
    dashboard: Yup.string().optional().nullable(),
    version: Yup.string().optional().nullable(),
    swagger: Yup.string().optional().nullable(),
    id: Yup.string().optional(),
    description: Yup.string()
      .required('La description du service est requis'),
    kind: Yup.string().required('Le type de service est requis'),
    tags: Yup.array(
      Yup.string()
        .min(3, "Les tags doivent être plus long que 3 caractères")
        .required()),
    irtInfo: Yup.object().shape({
      project: Yup.string()
        .required('Le projet du service est requis'),
      theme: Yup.string()
        .required('Le theme du service est requis'),
      industry: Yup.string()
        .required('L\'industry du service est requis'),
      agavRef: Yup.string().optional().nullable(),
      contributors: Yup.array(
        Yup.string()
          .min(5, "Le nom des contributeurs doivent être plus long que 5 caractères"))
    })
  }
)


export const formikProps = (formik: any, name: string) => ({
  ...formik.getFieldProps(name),
  error: formik.getFieldMeta(name).error
})

export const nameToHandle = (name: string) => name.replace(/ /g, '-').toLowerCase()