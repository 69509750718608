import React, {useContext} from 'react';
import Alert, {AlertProps} from 'components/alert/Alert'
import {AlertContext} from 'App'

interface Props {
  className?: string
}

const AlertManager: React.FC<Props> = ({className}) => {
  const alerts = useContext(AlertContext)[0]

  return (
    <div className={className}>
      {
        alerts.map((a, index) => <Alert key={index} {...a}/>)
      }
    </div>
  )
}

export const useAlert = () => {
  const [alerts, setAlerts] = useContext(AlertContext)

  return [
    (alert: AlertProps) => setAlerts([...alerts, alert]),
    (alert: AlertProps) => setAlerts(alerts.filter(a => alert.text === a.text)),
  ]

}

export default AlertManager 