import React from 'react';
import logo from "assets/logo_systemx_bl_const_rvb-transparent.png";
import Search from 'layout/sidebar/Search'
import {Link} from 'react-router-dom'
import Filter from 'common/Filter'
import Menu from 'layout/sidebar/Menu'
import AddService from 'layout/sidebar/AddService'
import {useRights} from 'config/auth'


interface Props {
  filters: Array<Filter>
  setFilter: (filters: Filter[]) => void
}

const SideBar: React.FC<Props> = ({filters, setFilter}) => {
  const canAdd = useRights()[1]

  return (
    <nav className="w-64 h-screen bg-gray-100 border-r sticky inset-0 flex flex-col justify-between pl-1 flex-shrink-0">
      <Link to="/" onClick={() => setFilter([])}>
        <img className="p-2" src={logo} alt="Logo"/>
      </Link>

      <hr/>
      <Menu filters={filters} setFilter={setFilter}/>
      <Search filters={filters} setFilter={setFilter}/>
      <hr/>
      {canAdd && <AddService className="self-center mt-3"/>}
    </nav>
  )
}

export default SideBar