import React, {useState} from 'react';
import SideBar from 'layout/sidebar/SideBar'
import "swagger-ui-react/swagger-ui.css"

import {Route, Switch} from "react-router-dom";
import {KeycloakProvider} from '@react-keycloak/web'
import {initConfig, keycloak} from 'config/auth'

import ServiceView from 'layout/main/view/ServiceView'
import ServiceGrid from 'layout/main/home/ServiceGrid'
import Filter from 'common/Filter'
import ServiceForm from 'layout/main/form/ServiceForm'
import ProtectedRoute from 'components/auth/ProtectedRoute'
import AlertManager from 'components/alert/AlertManager'
import {AlertProps} from 'components/alert/Alert'


export const AlertContext = React.createContext<[Array<AlertProps>, (alerts: AlertProps[]) => void]>(
  [[],
    () => {
    }])

const App = () => {
  const [filters, setFilters] = useState<Array<Filter>>([])
  const [alerts, setAlerts] = useState<Array<AlertProps>>([])

  return (
    <KeycloakProvider keycloak={keycloak} initConfig={initConfig}>
      <AlertContext.Provider value={[alerts, setAlerts]}>
        <div className="flex">
          <SideBar filters={filters} setFilter={setFilters}/>
          <main className="flex-grow">
            {/** Header **/}
            <div className="flex justify-around bg-gray-800 text-white">
              <h1
                className="text-center flex-grow font-semibold text-4xl ">
                Catalogue des services de l'IRT SystemX
              </h1>
              <div className="self-center mr-3">
                <button className="p-2 bg-primary ml-2 text-white rounded" type="button"
                        onClick={() => keycloak.logout()}>
                  Logout
                </button>
              </div>
            </div>
            <AlertManager className="fixed right-0 mr-2 w-1/5"/>
            <Switch>
              <ProtectedRoute path="/form/:handle" children={<ServiceForm/>}
                              fallback={<ServiceGrid filters={filters} setFilter={setFilters}/>}/>
              <ProtectedRoute path="/form" children={<ServiceForm/>}
                              fallback={<ServiceGrid filters={filters} setFilter={setFilters}/>}/>
              <Route path="/:handle" children={<ServiceView/>}/>
              <Route children={<ServiceGrid filters={filters} setFilter={setFilters}/>}/>
            </Switch>
          </main>
        </div>
      </AlertContext.Provider>
    </KeycloakProvider>
  );
}

export default App


