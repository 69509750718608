import React from 'react';
import SwaggerSVG from 'assets/svg/SwaggerSVG'
import GitLabSVG from 'assets/svg/GitLabSVG'

export const INDUSTRY_BG_IMAGE: { [type: string]: string } = {
  "Mobilité et transport autonome": "https://www.irt-systemx.fr/wp-content/uploads/2019/07/Section-1-photo-1Overlay.png",
  "Industrie du futur": "https://irt-systemx.fr/wp-content/uploads/2019/07/Section-1-photo-2Overlay.png",
  "Défense et sécurité": "https://irt-systemx.fr/wp-content/uploads/2019/07/Section-1-photo-3Overlay.png",
  "Environnement et développement durable": "https://irt-systemx.fr/wp-content/uploads/2019/07/Section-1-photo-4Overlay.png",
}

export const iconElement: { [type: string]: JSX.Element } = {
  docker: <><span className="ml-2 mr-4" role="img" aria-label="package">📦</span><span className="text-white">Docker</span></>,
  backend: <><span className="ml-2 mr-4" role="img" aria-label="package">🗄️</span><span className="text-white">Backend</span></>,
  dashboard: <><span className="ml-2 mr-4" role="img" aria-label="package">🖥️</span><span className="text-white">Dashboard</span></>,
  wiki: <><span className="ml-2 mr-4" role="img" aria-label="package">📖</span><span className="text-white">Wiki</span></>,
  swagger: <><SwaggerSVG className="w-5 h-5 inline ml-2 mr-4"/><span className="text-white">Swagger</span></>,
  git: <><GitLabSVG className="w-5 h-5 inline ml-2 mr-4"/><span className="text-white">GitLab</span></>
}