import React from 'react';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
  label?: string
  className?: string
  error?: string
}

const Input = ({label, className, error, ...inputProps}: Props) => {
  const errorStyle = error ? 'border-red-500 mb-1' : ''
  return (
    <div className={className}>
      {label && <label className="block text-gray-700 text-sm font-bold mb-2">
        {label}
      </label>}
      <input
        className={`${errorStyle} shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
        {...inputProps} />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  )
}

export default Input