import React from 'react';
import {RouteProps, Route} from 'react-router-dom'
import {useRights} from 'config/auth'

interface Props extends RouteProps{
fallback?: JSX.Element
}

const ProtectedRoute: React.FC<Props> = ({children,fallback,...rest}) => {
  const allowed = useRights()[1]
  return (
    <Route
      {...rest}
      render={({ location }) =>
        allowed ? (
          children
        ) : fallback
      }
    />
  )
}

export default ProtectedRoute